<template>
	<div id="openCashierBank" class="input-heading">
		<h3>{{ languageStrings.openBankText }}</h3>
		<div class="input-section">
			<label for="openingBalanceCOC">{{ languageStrings.openingBalanceLabel }}</label>
			<input
				type="hidden"
				name="openingBalanceAU"
				@click="numInputPadInit('openingBalanceAU')"
				v-model="openingBalanceAU"
				:placeholder="languageStrings.openingBalanceLabel"
				autocomplete="off"
				onfocus="blur();"
			/>
			<input
				type="text"
				name="openingBalancString"
				@click="numInputPadInit('openingBalanceAU')"
				v-model="openingBalancString"
				:placeholder="languageStrings.openingBalanceLabel"
				autocomplete="off"
				onfocus="blur();"
			/>
			<button class="open-bank btn" type="button" @click="openCashierBank()" :title="languageStrings.openBankText">
				{{ languageStrings.openBankText }}
			</button>
		</div>
		<transition name="fade">
			<NumInputPad
				v-if="inputConfig.inputField"
				:inputConfig="inputConfig"
				:currencyInfo="currencyInfo"
				:inGameCurrencyTool="inGameCurrencyTool"
				:systemCurrencyTool="systemCurrencyTool"
			/>
		</transition>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import NumInputPad from "@/components/NumInputPad.vue";
import router from "@/router/index";
import { onBeforeUnmount } from "vue";

export default {
	name: "OpenCashierBank",
	inheritAttrs: false,
	components: {
		NumInputPad,
	},
	appDataBus: {
		type: Object,
	},
	props: {
		cashierState: Object,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		casinoList: Array,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			inputConfig: Object.assign({}, this.globalInputConfig),
			username: "",
			password: "",
			userId: "",
			casinoId: "",
			cashierBankId: "",
			openingBalanceAU: 0,
			openingBalanceCOC: 0,
			openingBalancString: "0",
			bankList: [],
			currencyInfo: this.cashierState.currencyInfo,
			systemCurrencyInfo: this.systemCurrencyTool.currencyInfo,
			systemMinorOrFull: this.systemCurrencyTool.displayType.minorOrFull,
			casinoListForUser: [],
			selectedCasino: {},
			selectedCasinoId: this.cashierState?.casinoId,
		};
	},
	watch: {
		openingBalanceAU() {
			this.openingBalancString = this.systemCurrencyTool.formatFromAU(this.openingBalanceAU, this.systemMinorOrFull);
		},
	},
	created() {
		this.eventBus.on("closeKeypad", () => {
			this.inputConfig.inputField = null;
		});
		this.eventBus.emit("resetCurrencyToolConstructor", this.systemCurrencyInfo);
		onBeforeUnmount(() => {
			this.inputConfig.inputField = null;
			this.eventBus.off("closeKeypad");
		});
	},
	async mounted() {
		// Check if session needs to be refreshed
		let success = await this.authenticationCheck(this);
		if (success.hasOwnProperty("ok") && !success.ok) {
			this.serverStatus.serverBusy = false;
			this.serverStatus.busyText = "";
			return false;
		}

		let banks = await sharedScripts.getBanksList(this, true);

		let fetchStatus = sharedScripts.checkFetchErrors(banks);

		this.bankList = banks;

		if (fetchStatus && !fetchStatus.ok) {
			console.warn(fetchStatus);
			this.eventBus.emit("updateStatus", fetchStatus);
			if (fetchStatus.code === 409 || fetchStatus.code === 401) this.eventBus.emit("forceLogout");
			return false;
		}
	},
	methods: {
		numInputPadInit(inputField) {
			this.inputConfig.inputField = inputField;
			this.inputConfig.locale = true;
		},
		async openCashierBank() {
			let STATE = this.cashierState;
			let openingBalanceCOC = this.systemCurrencyTool.toCurrencyFromAU(this.openingBalanceAU);

			switch (true) {
				case !sharedScripts.isValidOpenValue(openingBalanceCOC):
					this.status.message =
						openingBalanceCOC < 0
							? this.languageErrorStrings.openingBalanceNegativeNumber
							: this.languageErrorStrings.openingBalanceGreaterThanMaxAllowed;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					return;
				case !openingBalanceCOC:
					this.status.message = this.languageErrorStrings.mustEnterSomeAmountFillBank;
					this.status.ok = false;
					this.eventBus.emit("updateStatus", this.status);
					return;
			}

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			let body = {
				casinoId: this.selectedCasinoId,
				openingBalanceCOC: openingBalanceCOC,
			};

			let requestUrl = new URL("/api/v1/cashier/bank/open", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.cashierState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok && !response.cashierBank) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409 || fetchStatus.code === 401) this.eventBus.emit("forceLogout");
					return false;
				}

				var dataJson = await response.json();

				switch (dataJson.status) {
					case "BankAlreadyOpen":
						if (this.cashierState?.currentBank?.id || this?.bankList[0]?.id) {
							this.status.message = `${this.languageErrorStrings.alreadyHaveBank} #${this.cashierState?.currentBank?.id ||
								this?.bankList[0]?.id} ${this.languageErrorStrings.openAtCasino}`;
							let highlightBank = {};
							highlightBank.blinkBankid = this.cashierState?.currentBank?.id || this?.bankList[0]?.id;
							this.eventBus.emit("updateAppDataBus", highlightBank);
							router.push("/listCashierBanks");
						} else {
							this.status.message = this.languageErrorStrings.bankAlreadyOpenDifferentCasino;
						}
						this.status.ok = false;
						this.eventBus.emit("updateStatus", this.status);
						return;
					case "InvalidTransferParameters":
						this.status.message = this.languageErrorStrings.invalidTransferParameters;
						this.status.ok = false;
						this.eventBus.emit("updateStatus", this.status);
						return;
					case "Success":
						this.status.message = `${this.languageStrings.openBankText} ${dataJson.cashierBank.id} ${this.languageStrings.success}`;
						this.status.ok = true;
						STATE.cashierBankId = dataJson.cashierBank.id;
						STATE.currentBank = dataJson.cashierBank;
						this.session.add("currentBank", dataJson.cashierBank);
						break;
				}

				this.eventBus.emit("printTicket", this.prepareOpeningReceipt(dataJson.cashierBank));

				this.openingBalanceCOC = 0;
				this.eventBus.emit("updateCashierState", STATE);
				this.eventBus.emit("updateStatus", this.status);
				router.push("/bankView");
			} catch (e) {
				console.error(e);
			}
		},
		prepareOpeningReceipt(newBank) {
			let minorOrFull = this.systemCurrencyTool.displayType.minorOrFull;

			// First element of printLines array; Initialize printer, set the code page to 16 (Windows-1252),
			// and set justify center for the header section.  The Windows-1252 encoding gives us access to
			// several currency symbols.  For this to work, we need to use the win1252Encoder later when
			// converting all this to bytes for the serial port.
			// https://en.wikipedia.org/wiki/Windows-1252
			let printLines = [`${this.textCodes.ESC}@${this.textCodes.ESC}t\x10${this.textCodes.ESC}a1`];

			// Header and site branding section
			printLines.push(this.setFontSize(this.languageStrings.cashierReceiptText, "\x10"));
			printLines.push(this.textCodes.STARDIVIDER);
			printLines.push(this.textCodes.LF);
			printLines.push(this.setFontSize(this.cashierState.casinoName, "\x11"));
			printLines.push(this.textCodes.LF);
			printLines.push(this.textCodes.STARDIVIDER);
			printLines.push(this.setFontSize(this.languageStrings.openBankText, "\x20"));
			printLines.push(this.textCodes.LF);
			printLines.push(new Date(newBank.openDate).toLocaleTimeString([], this.dateOptions));
			printLines.push(this.textCodes.LF);

			// Balance section
			printLines.push(
				this.formatSpacedLine(`${this.languageStrings.openingBalanceLabel}:`, this.systemCurrencyTool.formatCurrency(newBank.currentBalanceCOC, minorOrFull))
			);
			printLines.push(this.textCodes.LF);

			// Footer section
			printLines.push(this.textCodes.LINEDIVIDER);
			printLines.push(this.textCodes.LF);
			printLines.push(`${this.languageStrings.cashierText}: ${this.cashierState.displayName}`);
			printLines.push(this.textCodes.LF);
			printLines.push(this.textCodes.STARDIVIDER);

			// Feed a couple of lines and cut paper
			printLines.push(this.textCodes.LF);
			printLines.push(this.textCodes.LF);
			printLines.push(`${this.textCodes.GS}VB${66}`);

			let printJob = printLines.join(`${this.textCodes.LF}`);

			return printJob;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#openCashierBank .input-section {
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%), inset -2px -2px 10px 2px rgb(0 0 0 / 30%);
}

.open-bank {
	margin: 5px auto;
}
</style>
